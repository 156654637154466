<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h2>Definições</h2>
    <hr />
    <div style="text-align: center">
      <b-button class="shipping" @click="movement('Shipping')">Shipping</b-button>
      <b-button class="autoPrice" @click="movement('Auto')">Auto-Price</b-button>
      <b-button class="autoPrice" @click="movement('Stock')">Stock</b-button>
      <b-button class="autoPrice" @click="movement('Feed')">Feed</b-button>
      <b-button class="autoPrice" @click="movement('Other')">Outras</b-button>
    </div>
    <hr />
    <div v-if="this.page == 'Shipping'">
      <h4 style="text-align: center">CTT</h4>
      <div class="container">
        <div class="row">
          <div class="col">
            Envios Correio Azul: <b v-if="checked == false">Desligado</b>
            <b v-else>Ligado</b>
          </div>
          <div class="col">
            <b-form-checkbox v-model="checked" name="check-button" @change="updateValid()" switch> </b-form-checkbox>
          </div>
        </div>
      </div>
      <hr />
      <br />
      <h4 style="text-align: center">Shipping</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Companhia</th>
            <th>Fuel</th>
            <th>Inflação</th>
            <th>Seguro (por Kg)</th>
            <th>Taxa mau serviço</th>
            <th>Taxa mau serviço (Vol)</th>
            <th></th>
          </tr>
          <tr>
            <td>CTT</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.ctt.fuel" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.ctt.inflation" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.ctt.insurance" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.ctt.bad_service" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.ctt.bad_service_vol" />
            </td>
            <td>
              <a id="updateValues" @click="updateVariables(shippingVariables.ctt, 'CTT')">🔄</a>
            </td>
          </tr>
          <tr>
            <td>CTT Expresso</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.ctt_expresso.fuel" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.ctt_expresso.inflation" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.ctt_expresso.insurance" />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                v-model="shippingVariables.ctt_expresso.bad_service"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                v-model="shippingVariables.ctt_expresso.bad_service_vol"
              />
            </td>
            <td>
              <a id="updateValues" @click="updateVariables(shippingVariables.ctt_expresso, 'CTT Expresso')">🔄</a>
            </td>
          </tr>
          <tr>
            <td>DHL</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.dhl.fuel" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.dhl.inflation" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.dhl.insurance" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.dhl.bad_service" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.dhl.bad_service_vol" />
            </td>
            <td>
              <a id="updateValues" @click="updateVariables(shippingVariables.dhl, 'DHL')">🔄</a>
            </td>
          </tr>
          <tr>
            <td>FedEx</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.fedex.fuel" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.fedex.inflation" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.fedex.insurance" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.fedex.bad_service" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="shippingVariables.fedex.bad_service_vol" />
            </td>
            <td>
              <a id="updateValues" @click="updateVariables(shippingVariables.fedex, 'FedEx')">🔄</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="this.page == 'Auto'">
      <h4 style="text-align: center">Preço Automático</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Site</th>
            <th>Margem</th>
            <th>Free Shipping</th>
            <th>% Pagamento</th>
            <th>Preço (Kg)</th>
            <th>Multiplicação</th>
            <th>Soma</th>
            <th></th>
          </tr>
          <tr>
            <td>Idivia</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.idivia.margin" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.idivia.free_shipping" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.idivia.payment_perc" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.idivia.kg_price" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.idivia.mult_price" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.idivia.sum_price" />
            </td>
            <td>
              <a id="updateValues" @click="updatePriceVariables(priceAutoVariavles.idivia, 'Idivia')">🔄</a>
            </td>
          </tr>
          <tr>
            <td>Kiooli</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.kiooli.margin" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.kiooli.free_shipping" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.kiooli.payment_perc" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.kiooli.kg_price" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.kiooli.mult_price" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="priceAutoVariavles.kiooli.sum_price" />
            </td>
            <td>
              <a id="updateValues" @click="updatePriceVariables(priceAutoVariavles.kiooli, 'Kiooli')">🔄</a>
            </td>
          </tr>
        </table>
      </div>
      <hr />
      <h4 style="text-align: center">Intervalos</h4>
      <hr />
      <div style="text-align: center">
        <b-button class="shipping" @click="changeIntervalos('idivia')">Idivia</b-button>
        <b-button class="autoPrice" @click="changeIntervalos('kiooli')">Kiooli</b-button>
      </div>
      <hr />
      <div style="text-align: center">
        <b-button class="shipping" @click="openModal()">➕</b-button>
      </div>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Site</th>
            <th>€ Inicial</th>
            <th>€ Final</th>
            <th>€ Mult.</th>
            <th>€ Soma</th>
            <th>€ Fixo</th>
            <th>P. Inicial</th>
            <th>P. Final</th>
            <th>P. Mult.</th>
            <th>P. Soma</th>
            <th>P. Fixo</th>
            <th></th>
            <th></th>
          </tr>
          <tr v-for="inter in intervalos_show" :key="inter.id">
            <td>{{ inter.site }}</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_initial" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_final" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_mult" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_sum" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.price_fix" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_initial" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_final" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_mult" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_sum" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="inter.weight_fix" />
            </td>
            <td>
              <a id="updateValues" @click="updateIntervalo(inter)">🔄</a>
            </td>
            <td>
              <a id="updateValues" @click="deleteIntervalo(inter)">❌</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="this.page == 'Stock'">
      <h4 style="text-align: center">Update Variables</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Site</th>
            <th>Price %</th>
            <th>Stock %</th>
            <th></th>
          </tr>
          <tr v-for="stock in update_stock" :key="stock.type">
            <td>{{ stock.site }}</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="stock.price_perc" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="stock.stock_perc" />
            </td>
            <td>
              <a id="updateValues" @click="updateStockVariables(stock)">🔄</a>
            </td>
          </tr>
        </table>
      </div>
      <h4 style="text-align: center">Stock Status</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Tipo</th>
            <th>Stock Min.</th>
            <th>Stock Máx.</th>
            <th>Preço Aumento %</th>
            <th></th>
          </tr>
          <tr v-for="stock in stock_status" :key="stock.type">
            <td>{{ stock.type }}</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="stock.stock_min" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="stock.stock_max" />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                v-model="stock.price_change"
                :disabled="shouldDisable(stock.type)"
              />
            </td>
            <td>
              <a id="updateValues" @click="updateStockStatus(stock)">🔄</a>
            </td>
          </tr>
        </table>
      </div>
      <h4 style="text-align: center">Price Options</h4>
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Level</th>
            <th>Margem</th>
            <th>Validade</th>
            <th>Última Receção</th>
            <th>Última Venda</th>
            <th>Stock</th>
            <th>Auto</th>
            <th></th>
          </tr>
          <tr v-for="price in price_options" :key="price.level">
            <td>{{ price.level }}</td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.margin" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.validity" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.last_reception" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.last_sale" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.stock" />
            </td>
            <td>
              <input id="length" type="text" class="form-control" v-model="price.auto_days" />
            </td>
            <td>
              <a id="updateValues" @click="updatePriceOptions(price)">🔄</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="this.page == 'Feed'">
      <Feed />
    </div>
    <div v-if="this.page == 'Other'">
      <div class="validationAdress">
        <h4 style="text-align: center">Validação de Moradas</h4>
        <label for="">Tipo de Validação</label> <br />
        <select
          class="form-select form-select"
          aria-label=".form-select-lg example"
          v-model="status"
          @change="validationAddressStatus()"
        >
          <option value="off">Desligado</option>
          <option value="manual">Manual</option>
          <option value="logistica">Logistica</option>
        </select>
      </div>
      <hr />
      <div class="container">
        <table class="table">
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Desconto</th>
          </tr>
          <tbody v-for="disc in allDiscounts" :key="disc.id">
            <tr>
              <td>{{ disc.id }}</td>
              <td>{{ disc.email }}</td>
              <td style="width: 20%">
                <input type="number" style="width: 30%" v-model="disc.discount" @change="editDiscount(disc)" />%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal ref="my-modal" id="modal-1" title="Adicionar Intervalo" hide-footer>
      <form v-on:submit.prevent="addIntervalo()">
        <div class="form-group">
          <label for="addressInput">€ Inicial:</label>
          <input type="text" class="form-control" id="addressInput" v-model="price_initial" />
        </div>
        <div class="form-group">
          <label for="cityInput">€ Final:</label>
          <input type="text" class="form-control" id="cityInput" v-model="price_final" />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">€ Mult. :</label>
          <input type="text" class="form-control" id="postalCodeInput" v-model="price_mult" />
        </div>
        <div class="form-group">
          <label for="stateInput">€ Soma:</label>
          <input type="text" class="form-control" id="stateInput" v-model="price_sum" />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">€ Fixo:</label>
          <input type="text" class="form-control" id="stateInput" v-model="price_fix" />
        </div>
        <div class="form-group">
          <label for="addressInput">Peso Inicial:</label>
          <input type="text" class="form-control" id="addressInput" v-model="weight_initial" />
        </div>
        <div class="form-group">
          <label for="cityInput">Peso Final:</label>
          <input type="text" class="form-control" id="cityInput" v-model="weight_final" />
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Peso Mult. :</label>
          <input type="text" class="form-control" id="postalCodeInput" v-model="weight_mult" />
        </div>
        <div class="form-group">
          <label for="stateInput">Peso Soma:</label>
          <input type="text" class="form-control" id="stateInput" v-model="weight_sum" />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">Peso Fixo:</label>
          <input type="text" class="form-control" id="stateInput" v-model="weight_fix" />
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">Adicionar Intervalo</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
// import { mapGetters } from "vuex";
import Loading from '@/components/Loading.vue';
import Feed from '@/components/FeedSettings.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Loading,
    Feed,
  },
  data: function () {
    return {
      page: '',
      intervalo: '',
      intervalos: [],
      intervalos_show: [],
      checked: '',
      loading: false,
      shippingVariables: [],
      priceAutoVariavles: [],
      stock_status: [],
      price_options: [],
      update_stock: [],
      //Modal
      price_initial: '',
      price_final: '',
      price_mult: '',
      price_sum: '',
      price_fix: '',
      weight_initial: '',
      weight_final: '',
      weight_mult: '',
      weight_sum: '',
      weight_fix: '',
      validationAdress: [],
      status: '',
      allDiscounts: [],
    };
  },
  methods: {
    //Basic
    movement(new_page) {
      this.page = new_page;
    },
    changeIntervalos(site) {
      this.intervalo = site;
      this.intervalos_show = this.intervalos.filter((item) => item.site === this.intervalo);
    },
    async openModal() {
      this.$refs['my-modal'].show();
    },
    async getValid() {
      try {
        await this.$store.dispatch('cttValid');
        this.checked = this.getValidCtt;
      } catch (err) {
        alert(err);
      }
    },
    async updateValid() {
      try {
        await this.$store.dispatch('updateCttValid');
        this.getValid();
      } catch (err) {
        alert(err);
      }
    },
    async getShipping() {
      try {
        await this.$store.dispatch('getShippingVariables');
        this.shippingVariables = this.getShippingVariables;
        console.log('1', this.shippingVariables);
      } catch (err) {
        alert(err);
      }
    },
    async updateVariables(company, name) {
      try {
        await this.$store.dispatch('updateShippingVariables', {
          company: company.company,
          fuel: company.fuel,
          inflation: company.inflation,
          insurance: company.insurance,
          back_service: company.bad_service,
          back_service_vol: company.bad_service_vol,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Variáveis ${name} atualizado`,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        alert(err);
      }
    },
    //Auto Price
    async getPrice() {
      try {
        await this.$store.dispatch('getPriceVariables');
        this.priceAutoVariavles = this.getPriceVariables;
        console.log('2', this.getPriceVariables);
      } catch (err) {
        alert(err);
      }
    },
    async updatePriceVariables(site, name) {
      if (site.mult_price < 0.95 || site.sum_price < 0) {
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: `Valores não válidos.`,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      try {
        await this.$store.dispatch('updatePriceVariables', {
          site: site.site,
          margin: site.margin,
          free_shipping: site.free_shipping,
          payment_perc: site.payment_perc,
          kg_price: site.kg_price,
          mult_price: site.mult_price,
          sum_price: site.sum_price,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Variáveis ${name} atualizado`,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        alert(err);
      }
    },
    async getIntervalos() {
      try {
        await this.$store.dispatch('getPriceIntervalos');
        this.intervalos = this.getPriceIntervalos;
        this.intervalos_show = this.intervalos.filter((item) => item.site === this.intervalo);
      } catch (err) {
        alert(err);
      }
    },
    async addIntervalo() {
      try {
        await this.$store.dispatch('addPriceIntervalos', {
          price_initial: this.price_initial ?? null,
          price_final: this.price_final ?? null,
          price_mult: this.price_mult ?? null,
          price_sum: this.price_sum ?? null,
          price_fix: this.price_fix ?? null,
          weight_initial: this.weight_initial ?? null,
          weight_final: this.weight_final ?? null,
          weight_mult: this.weight_mult ?? null,
          weight_sum: this.weight_sum ?? null,
          weight_fix: this.weight_fix ?? null,
          site: this.intervalo,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Intervalo Adicionado`,
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getIntervalos();
        this.$refs['my-modal'].hide();
      } catch (error) {
        alert(error);
      }
    },
    async updateIntervalo(inter) {
      this.$swal({
        title: 'Quer atualizar o intervalo?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, atualizar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('updatePriceIntervalos', {
              id: inter.id,
              price_initial: inter.price_initial ?? null,
              price_final: inter.price_final ?? null,
              price_mult: inter.price_mult ?? null,
              price_sum: inter.price_sum ?? null,
              price_fix: inter.price_fix ?? null,
              weight_initial: inter.weight_initial ?? null,
              weight_final: inter.weight_final ?? null,
              weight_mult: inter.weight_mult ?? null,
              weight_sum: inter.weight_sum ?? null,
              weight_fix: inter.weight_fix ?? null,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Intervalo atualizado`,
              showConfirmButton: false,
              timer: 1500,
            });
            await this.getIntervalos();
          } catch (error) {
            alert(error);
          }
        }
      });
    },
    async deleteIntervalo(inter) {
      this.$swal({
        title: 'Quer eliminar o intervalo?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eliminar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('deletePriceIntervalos', {
              id: inter.id,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Intervalo eliminado`,
              showConfirmButton: false,
              timer: 1500,
            });
            await this.getIntervalos();
          } catch (error) {
            alert(error);
          }
        }
      });
    },
    async validationAddressGet() {
      try {
        await this.$store.dispatch('addressValidation');
        console.log(this.getValidationAddress);
        this.status = this.getValidationAddress.status;
      } catch (err) {
        alert(err);
      }
    },
    async validationAddressStatus() {
      try {
        await this.$store.dispatch('addressValidationStatus', {
          status: this.status,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Validação de Moradas Atualizada!`,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        alert(err);
      }
    },
    async getStockStatusVar() {
      try {
        await this.$store.dispatch('getStockStatusVar');
        console.log(this.getStockStatus);
        this.stock_status = this.getStockStatus;
      } catch (err) {
        alert(err);
      }
    },
    async updateStockStatus(status) {
      this.$swal({
        title: 'Quer atualizar ' + status.type + '?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, atualizar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('updateStockStatusVar', {
              type: status.type,
              max: status.stock_max,
              min: status.stock_min,
              price: status.price_change,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Intervalo atualizado`,
              showConfirmButton: false,
              timer: 1500,
            });
            await this.getIntervalos();
          } catch (error) {
            alert(error);
          }
        }
      });
    },
    async getPriceOptionsVar() {
      try {
        await this.$store.dispatch('getPriceOptionsVar');
        console.log(this.getPriceOptions);
        this.price_options = this.getPriceOptions;
        console.log('AQUIII', this.price_options, this.getPriceOptions);
      } catch (err) {
        alert(err);
      }
    },
    async updatePriceOptions(status) {
      this.$swal({
        title: 'Quer atualizar ' + status.level + '?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, atualizar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('updatePriceOptionsVar', {
              level: status.level,
              margin: status.margin,
              validity: status.validity,
              last_reception: status.last_reception,
              last_sale: status.last_sale,
              stock: status.stock,
              auto_days: status.auto_days,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Intervalo atualizado`,
              showConfirmButton: false,
              timer: 1500,
            });
            await this.getIntervalos();
          } catch (error) {
            alert(error);
          }
        }
      });
    },
    async getStockUpdateVar() {
      try {
        await this.$store.dispatch('getStockUpdateVar');
        this.update_stock = this.getStockVariables;
      } catch (err) {
        alert(err);
      }
    },
    async updateStockVariables(site_var) {
      this.$swal({
        title: 'Quer atualizar ' + site_var.site + '?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, atualizar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('updateStockVariables', {
              site: site_var.site,
              price_perc: site_var.price_perc,
              stock_perc: site_var.stock_perc,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: `Variáveis atualizadas`,
              showConfirmButton: false,
              timer: 1500,
            });
            await this.getIntervalos();
          } catch (error) {
            alert(error);
          }
        }
      });
    },
    shouldDisable(type) {
      return type === 'Infinity' || type === 'MinC No';
    },
    async getDiscount() {
      try {
        await this.$store.dispatch('allClientsDiscounts');
        this.allDiscounts = this.getclientDiscount;
        console.log(this.allDiscounts);
      } catch (error) {
        console.log(error);
      }
    },
    async editDiscount(data) {
      console.log(data);
      try {
        await this.$store.dispatch('updateDiscountClients', {
          id: data.id,
          discount: data.discount,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Desconto atualizado`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getDiscount();
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    await this.getValid();
    await this.getShipping();
    this.page = 'Shipping';
    this.intervalo = 'idivia';
    await this.getPrice();
    await this.getIntervalos();
    await this.validationAddressGet();
    await this.getStockStatusVar();
    await this.getPriceOptionsVar();
    await this.getStockUpdateVar();
    await this.getDiscount();
  },
  computed: {
    ...mapGetters(['getValidCtt']),
    ...mapGetters(['getShippingVariables']),
    ...mapGetters(['getPriceVariables']),
    ...mapGetters(['getPriceIntervalos']),
    ...mapGetters(['getValidationAddress']),
    ...mapGetters(['getStockStatus']),
    ...mapGetters(['getPriceOptions']),
    ...mapGetters(['getclientDiscount']),
    ...mapGetters(['getStockVariables']),
  },
};
</script>
<style>
.container {
  text-align: center;
}
.validationAdress {
  text-align: center;
  margin: 0px 600px 0px 600px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}
table {
  margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  text-align: center;
}
th,
td {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: center;
  text-indent: -0.5em;
}

th {
  vertical-align: bottom;
  background-color: #666;
  color: #fff;
}

tr:nth-child(even) th[scope='row'] {
  background-color: #f2f2f2;
}

tr:nth-child(odd) th[scope='row'] {
  background-color: #fff;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: center;
}

#updateValues {
  margin-left: 5px;
  cursor: pointer;
}
#updateValues:hover {
  cursor: pointer;
}

.shipping {
  margin: 0px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.shipping:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}

.autoPrice {
  margin: 0px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.autoPrice:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
.table {
  text-align: center;
}
</style>
