<template>
  <div>
    <div style="text-align: center; margin-bottom: 20px">
      <button style="margin: 0px 5px" class="btn btn-primary" @click="movement('settings')">Definições</button>
      <button style="margin: 0px 5px" class="btn btn-primary" @click="movement('intervalos')">Intervalos</button>
      <button style="margin: 0px 5px" class="btn btn-primary" @click="movement('margem')">Margens</button>
      <button style="margin: 0px 5px" class="btn btn-primary" @click="movement('shipping')">Envios</button>
      <button style="margin: 0px 5px" class="btn btn-primary" @click="movement('noFeed')">Produtos não Feed</button>
    </div>
    <div v-if="page == 'settings'">
      <h4 style="text-align: center">Feed Settings</h4>
      <div style="align-items">
        <table style="width: 50%">
          <tr>
            <th>Feed</th>
            <th>Farmas</th>
            <th>OCP</th>
            <th>Nova Engel</th>
            <th>Apenas Site</th>
          </tr>
          <tr v-for="setting in settings" :key="setting.id">
            <td style="width: 20%">{{ setting.feed }}</td>
            <td style="width: 10%">
              <input
                type="number"
                class="form-control"
                v-model="setting.farmas_stock"
                @change="updateSettings(setting)"
              />
            </td>
            <td style="width: 10%">
              <input type="number" class="form-control" v-model="setting.ocp_stock" @change="updateSettings(setting)" />
            </td>
            <td style="width: 10%">
              <input
                type="number"
                class="form-control"
                v-model="setting.nova_engel"
                @change="updateSettings(setting)"
              />
            </td>
            <td style="width: 10%">
              <b-form-checkbox
                :id="'up_only_stock_' + setting.id"
                v-model="setting.only_site"
                switch
                @change="updateSettings(setting)"
              ></b-form-checkbox>
            </td>
          </tr>
        </table>
      </div>
      <h4 style="text-align: center">Feed Valores</h4>
      <div style="align-items: center">
        <table style="width: 60%">
          <tr>
            <th>Feed</th>
            <!-- <th>Soma</th>
            <th>Multiplo</th>
            <th>Margem</th> -->
            <th>Fee</th>
            <!-- <th>Shipping</th> -->
            <th>País</th>
          </tr>
          <tr v-for="value in values" :key="value.id">
            <td style="width: 20%">{{ value.feed }}</td>
            <!-- <td style="width: 10%">
              <input
                type="text"
                class="form-control"
                v-model="value.soma"
                @change="updateValues(value)"
              />
            </td>
            <td style="width: 10%">
              <input
                type="text"
                class="form-control"
                v-model="value.multiplo"
                @change="updateValues(value)"
              />
            </td>
            <td style="width: 10%">
              <input
                type="text"
                class="form-control"
                v-model="value.margem"
                @change="updateValues(value)"
              />
            </td> -->
            <td style="width: 10%">
              <input type="text" class="form-control" v-model="value.fee" @change="updateValues(value)" />
            </td>
            <!-- <td style="width: 10%">
              <input
                type="text"
                class="form-control"
                v-model="value.shipping"
                @change="updateValues(value)"
              />
            </td> -->
            <td style="width: 10%">
              {{ value.country }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else-if="page == 'intervalos'">
      <h4 style="text-align: center">Intervalos</h4>
      <!-- <div
        style="
          text-align: center;
          margin-top: 10px;
          width: 20%;
          margin: auto auto;
        "
      >
        <select
          class="form-control"
          v-model="feedChoose"
          @change="filterMargem(feedChoose)"
        >
          <option value="all">Todos</option>
          <option value="kuanto kusta">Kuanto Kusta</option>
          <option value="perfumes club">Perfumes Club</option>
        </select>
      </div> -->
      <div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
        <button class="btn btn-primary" @click="openModal('modal_intervalo')">Adicionar Intervalo</button>
      </div>
      <div style="align-items: center">
        <table style="width: 100%">
          <tr>
            <th>Feed</th>
            <th>€ Inicial</th>
            <th>€ Final</th>
            <th>€ Mult.</th>
            <th>€ Soma</th>
            <th>€ Fixo</th>
            <th>P. Inicial</th>
            <th>P. Final</th>
            <th>P. Mult.</th>
            <th>P. Soma</th>
            <th>P. Fixo</th>
            <th>País</th>
            <th></th>
          </tr>
          <tr v-for="marge in intervals" :key="marge.id">
            <td style="width: 20%">{{ marge.feed }}</td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.price_initial"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.price_final"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.price_mult"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.price_sum"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.price_fix"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.weight_initial"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.weight_final"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.weight_mult"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.weight_sum"
              />
            </td>
            <td>
              <input
                id="length"
                type="text"
                class="form-control"
                @change="editInterval(marge)"
                v-model="marge.weight_fix"
              />
            </td>
            <td>
              {{ marge.country }}
            </td>
            <td>
              <a @click="deleteInterval(marge.id)">❌</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else-if="page == 'margem'">
      <h4 style="text-align: center">Margens dos Preços</h4>
      <div style="text-align: center; margin-top: 10px; width: 20%; margin: auto auto">
        <select class="form-control" v-model="feedChoose" @change="filterMargem(feedChoose)">
          <option value="all">Todos</option>
          <option value="kuanto kusta">Kuanto Kusta</option>
          <option value="perfumes club">Perfumes Club</option>
        </select>
      </div>
      <div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
        <button class="btn btn-primary" @click="openModal('modal_margem')">Adiconar Margem</button>
      </div>
      <div style="align-items: center">
        <table style="width: 60%">
          <tr>
            <th>Feed</th>
            <th>Minimo</th>
            <th>Máximo</th>
            <th>Margem</th>
            <th>País</th>
            <th>Remover</th>
          </tr>
          <tr v-for="marge in margens" :key="marge.id">
            <td style="width: 20%">{{ marge.feed }}</td>
            <td style="width: 10%">
              {{ marge.minimo }}
            </td>
            <td style="width: 10%">
              {{ marge.maximo }}
            </td>
            <td style="width: 10%">
              {{ marge.margem }}
            </td>
            <td style="width: 10%">
              {{ marge.country }}
            </td>
            <td style="width: 10%">
              <a @click="deleteMargem(marge.id)">❌</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else-if="page == 'shipping'">
      <h4 style="text-align: center">Preço dos Envios</h4>
      <div style="text-align: center; margin-top: 10px; width: 20%; margin: auto auto">
        <!-- <select
          class="form-control"
          v-model="feedChoose"
          @change="filterMargem(feedChoose)"
        >
          <option value="all">Todos</option>
          <option value="kuanto kusta">Kuanto Kusta</option>
          <option value="perfumes club">Perfumes Club</option>
        </select> -->
      </div>
      <div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
        <button class="btn btn-primary" @click="openModal('modal_shipping')">Adicionar Shipping</button>
      </div>
      <div style="align-items: center">
        <table style="width: 60%">
          <tr>
            <th>Feed</th>
            <th>Minimo</th>
            <th>Máximo</th>
            <th>Envio</th>
            <th>País</th>
            <th>Remover</th>
          </tr>
          <tr v-for="marge in shippings" :key="marge.id">
            <td style="width: 20%">{{ marge.feed }}</td>
            <td style="width: 10%">
              {{ marge.minimo }}
            </td>
            <td style="width: 10%">
              {{ marge.maximo }}
            </td>
            <td style="width: 10%">
              {{ marge.shipping }}
            </td>
            <td style="width: 10%">
              {{ marge.country }}
            </td>
            <td style="width: 10%">
              <a @click="deleteShipping(marge.id)">❌</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else-if="page == 'noFeed'">
      <h4 style="text-align: center">Produtos sem Feed</h4>
      <div style="text-align: center; margin-top: 10px; width: 20%; margin: auto auto"></div>
      <div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
        <button class="btn btn-primary" @click="openModal('modal_product')">Adicionar Produto</button>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">Feed:</span>
              </div>
              <select class="form-control" v-model="feedSearch" @change="filterNoProducts()">
                <option value="all">Todos</option>
                <option value="kuanto kusta">Kuanto Kusta</option>
                <option value="perfumes club">Perfumes Club</option>
                <option value="worten">Worten</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">SKU:</span>
              </div>
              <input type="text" v-model="skuProduct" class="form-control" @change="filterNoProducts()" />
            </div>
          </div>
        </div>
      </div>
      <div style="align-items: center">
        <table style="width: 60%">
          <tr>
            <th>ID</th>
            <th>SKU</th>
            <th>Envio</th>
            <th>Feed</th>
            <th></th>
          </tr>
          <tr v-for="no in noProducts" :key="no.id">
            <td>{{ no.id }}</td>
            <td>
              <b-link @click="routeToProduct(no.id_product)">{{ no.sku }}</b-link>
            </td>
            <td>
              {{ no.name_product }}
            </td>
            <td>
              {{ no.feed }}
            </td>
            <td>
              <a @click="deleteProduct(no.id)">❌</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <b-modal ref="modal_intervalo" id="modal-intervalo" :title="'Adicionar Intervalo'" hide-footer>
      <form v-on:submit.prevent="addInterval()">
        <div class="form-group">
          <label for="addressInput">Feed:</label>
          <select class="form-control" v-model="feed">
            <option value="kuanto kusta">Kuanto Kusta</option>
            <option value="perfumes club">Perfumes Club</option>
            <option value="worten">Worten</option>
          </select>
        </div>
        <div class="form-group" v-if="feed == 'perfumes club' || feed == 'worten'">
          <label for="choiceCountry">País:</label>
          <select class="form-control" v-model="country">
            <option value="all">Todos</option>
            <option value="DE">Alemanha</option>
            <option value="ES">Espanha</option>
            <option value="FR">França</option>
            <option value="PT">Portugal</option>
            <option value="IT">Itália</option>
          </select>
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Preço Custo Minimo:</label>
          <input type="number" class="form-control" id="postalCodeInput" v-model="price_initial" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Preço Custo Maximo:</label>
          <input type="number" class="form-control" id="cityInput" v-model="price_final" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Multiplo do Preço:</label>
          <input type="number" class="form-control" id="cityInput" v-model="price_mult" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Soma do Preço:</label>
          <input type="number" class="form-control" id="cityInput" v-model="price_sum" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Preço Fixo:</label>
          <input type="number" class="form-control" id="cityInput" v-model="price_fix" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Peso Inicial:</label>
          <input type="number" class="form-control" id="cityInput" v-model="weight_initial" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Peso Final:</label>
          <input type="number" class="form-control" id="cityInput" v-model="weight_final" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Multiplo Peso:</label>
          <input type="number" class="form-control" id="cityInput" v-model="weight_mult" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Soma Peso:</label>
          <input type="number" class="form-control" id="cityInput" v-model="weight_sum" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Peso Fixo:</label>
          <input type="number" class="form-control" id="cityInput" v-model="weight_fix" step="0.01" />
        </div>
        <div class="selectBtn">
          <button type="submit" class="btn btn-primary">Adicionar Intervalo</button>
        </div>
      </form>
    </b-modal>
    <b-modal ref="modal_shipping" id="modal-shipping" :title="'Adicionar Preços de Envio'" hide-footer>
      <form v-on:submit.prevent="addShipping()">
        <div class="form-group">
          <label for="addressInput">Feed:</label>
          <select class="form-control" v-model="feed">
            <option value="kuanto kusta">Kuanto Kusta</option>
            <option value="perfumes club">Perfumes Club</option>
            <option value="worten">Worten</option>
          </select>
        </div>
        <div class="form-group" v-if="feed == 'perfumes club' || feed == 'worten'">
          <label for="choiceCountry">País:</label>
          <select class="form-control" v-model="country">
            <option value="all">Todos</option>
            <option value="DE">Alemanha</option>
            <option value="ES">Espanha</option>
            <option value="FR">França</option>
            <option value="PT">Portugal</option>
            <option value="IT">Itália</option>
          </select>
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Peso Minimo:</label>
          <input type="number" class="form-control" id="postalCodeInput" v-model="minimo" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Peso Maximo:</label>
          <input type="number" class="form-control" id="cityInput" v-model="maximo" step="0.01" />
        </div>
        <div class="form-group">
          <label for="stateInput">Preço Envio:</label>
          <input type="number" class="form-control" id="stateInput" v-model="shipping" step="0.01" />
        </div>
        <div class="selectBtn">
          <button type="submit" class="btn btn-primary">Adicionar Intervalo</button>
        </div>
      </form>
    </b-modal>
    <b-modal ref="modal_margem" id="modal-margem" :title="'Adicionar Margem'" hide-footer>
      <form v-on:submit.prevent="addMargem()">
        <div class="form-group">
          <label for="addressInput">Feed:</label>
          <select class="form-control" v-model="feed">
            <option value="kuanto kusta">Kuanto Kusta</option>
            <option value="perfumes club">Perfumes Club</option>
            <option value="worten">Worten</option>
          </select>
        </div>
        <div class="form-group" v-if="feed == 'perfumes club' || feed == 'worten'">
          <label for="choiceCountry">País:</label>
          <select class="form-control" v-model="country">
            <option value="all">Todos</option>
            <option value="DE">Alemanha</option>
            <option value="ES">Espanha</option>
            <option value="FR">França</option>
            <option value="PT">Portugal</option>
            <option value="IT">Itália</option>
          </select>
        </div>
        <div class="form-group">
          <label for="postalCodeInput">Preço Custo Minimo:</label>
          <input type="number" class="form-control" id="postalCodeInput" v-model="minimo" step="0.01" />
        </div>
        <div class="form-group">
          <label for="cityInput">Preço Custo Maximo:</label>
          <input type="number" class="form-control" id="cityInput" v-model="maximo" step="0.01" />
        </div>
        <div class="form-group">
          <label for="stateInput">Margem:</label>
          <input type="number" class="form-control" id="stateInput" v-model="margem" step="0.01" />
        </div>
        <div class="selectBtn">
          <button type="submit" class="btn btn-primary">Adicionar Margem</button>
        </div>
      </form>
    </b-modal>
    <b-modal ref="modal_product" id="modal-margem" :title="'Adicionar Margem'" hide-footer>
      <div class="form-group">
        <label for="sku">SKU</label>
        <input type="text" class="form-control" id="sku" v-model="skuSearch" @change="getProductInfo()" />
      </div>
      <div class="form-group">
        <label for="addressInput">Feed:</label>
        <select class="form-control" v-model="feed" required>
          <option value="kuanto kusta">Kuanto Kusta</option>
          <option value="perfumes club">Perfumes Club</option>
          <option value="worten">Worten</option>
        </select>
      </div>
      <span v-if="product.length > 0">
        <img :src="product[0].img" alt="" style="width: 300px; height: 300px; margin-left: 15%" />
        <h6>{{ product[0].name }}</h6>
        <p><b>SKU:</b> {{ product[0].sku }}</p>
        <p><b>EAN:</b> {{ product[0].ean }}</p>
        <p><b>Stock:</b> {{ product[0].stock }}</p>
      </span>
      <div>
        <button class="btn btn-primary" @click="addNoProduct()">Adicionar Produto</button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      settings: [],
      values: [],
      feed: '',
      margem: '',
      maximo: '',
      minimo: '',
      intervals: [],
      page: 'settings',
      feedChoose: 'all',
      country: 'all',
      shippings: [],
      shipping: '',
      price_initial: '',
      price_final: '',
      price_mult: '',
      price_sum: '',
      price_fix: '',
      weight_initial: '',
      weight_final: '',
      weight_mult: '',
      weight_sum: '',
      weight_fix: '',
      margens: [],
      sku: '',
      product: [],
      skuSearch: '',
      noProducts: [],
      feedSearch: 'all',
      skuProduct: '',
    };
  },
  methods: {
    async filterMargem(feed) {
      if (feed == 'all') {
        await this.getMargem();
      } else {
        await this.getMargemByFeed(feed);
      }
    },
    async movement(page) {
      this.page = page;
    },
    async getFeedSettings() {
      console.log('getFeedSettings');
      try {
        await this.$store.dispatch('getFeedSettings');
        console.log(this.getAllFeedSettings);
        this.settings = this.getAllFeedSettings;
      } catch (err) {
        alert(err);
      }
    },
    async getFeedValues() {
      console.log('getFeedValues');
      try {
        await this.$store.dispatch('getFeedValues');
        console.log(this.getAllFeedValues);
        this.values = this.getAllFeedValues;
      } catch (err) {
        alert(err);
      }
    },
    async updateSettings(settings) {
      console.log('updateSettings');
      try {
        await this.$store.dispatch('updateFeedSettings', {
          feed: settings.feed,
          ocp_stock: settings.ocp_stock,
          nova_engel: settings.nova_engel,
          farmas_stock: settings.farmas_stock,
          only_site: settings.only_site,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Settings atualizados com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
      } catch (err) {
        alert(err);
      }
    },
    async updateValues(settings) {
      console.log('updateValues');
      try {
        await this.$store.dispatch('updateFeedValues', {
          feed: settings.id,
          soma: settings.soma,
          multiplo: settings.multiplo,
          margem: settings.margem,
          fee: settings.fee,
          shipping: settings.shipping,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Values atualizados com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
      } catch (err) {
        alert(err);
      }
    },
    async addInterval() {
      try {
        await this.$store.dispatch('addIntervalFeed', {
          price_initial: this.price_initial,
          price_final: this.price_final,
          price_mult: this.price_mult,
          price_sum: this.price_sum,
          price_fix: this.price_fix,
          weight_initial: this.weight_initial,
          weight_final: this.weight_final,
          weight_mult: this.weight_mult,
          weight_sum: this.weight_sum,
          weight_fix: this.weight_fix,
          feed: this.feed,
          country: this.country,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Margem adicionada com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.$refs['modal_intervalo'].hide();
        this.country = 'all';
        this.price_initial = '';
        this.price_mult = '';
        this.price_sum = '';
        this.price_fix = '';
        this.weight_initial = '';
        this.weight_mult = '';
        this.weight_sum = '';
        this.weight_fix = '';
        this.feed = '';
        await this.getInterval();
      } catch (err) {
        alert(err);
      }
    },
    async getInterval() {
      console.log('getMargem');
      try {
        await this.$store.dispatch('getIntervalFeed');
        console.log(this.getAllFeedMargem);
        this.intervals = this.getAllFeedInterval;
      } catch (err) {
        alert(err);
      }
    },
    async getMargemByFeed(feed) {
      try {
        await this.$store.dispatch('getMargemByFeed', {
          feed: feed,
        });
        this.margens = this.getAllFeedMargemByFeed;
      } catch (err) {
        alert(err);
      }
    },
    async deleteMargem(id) {
      try {
        await this.$store.dispatch('deleteFeedMargem', {
          id: id,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Margem apagado com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        await this.getMargem();
      } catch (err) {
        alert(err);
      }
    },
    async shippingFeed() {
      try {
        await this.$store.dispatch('getShippingFeed');
        this.shippings = this.getAllFeedShipping;
      } catch (err) {
        alert(err);
      }
    },
    async addShipping() {
      try {
        await this.$store.dispatch('addShippingFeed', {
          feed: this.feed,
          shipping: this.shipping,
          maximo: this.maximo,
          minimo: this.minimo,
          country: this.country,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Margem adicionada com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.$refs['modal_shipping'].hide();
        this.country = 'all';
        this.feed = '';
        this.shipping = '';
        this.maximo = '';
        this.minimo = '';
        await this.shippingFeed();
      } catch (err) {
        alert(err);
      }
    },
    async editInterval(values) {
      try {
        await this.$store.dispatch('updateFeedInterval', {
          id: values.id,
          price_initial: values.price_initial,
          price_final: values.price_final,
          price_mult: values.price_mult,
          price_sum: values.price_sum,
          price_fix: values.price_fix,
          weight_initial: values.weight_initial,
          weight_final: values.weight_final,
          weight_mult: values.weight_mult,
          weight_sum: values.weight_sum,
          weight_fix: values.weight_fix,
          margem: values.margem,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Margem editada com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        await this.getInterval();
      } catch (err) {
        alert(err);
      }
    },
    async getProductInfo() {
      try {
        await this.$store.dispatch('fetchProductsSKU', {
          sku: this.skuSearch,
        });
        console.log(this.getProduct);
        this.product = this.getProduct;
      } catch (error) {
        console.log(error);
      }
    },
    async getMargem() {
      try {
        await this.$store.dispatch('getMargemFeed');
        this.margens = this.getAllFeedMargem;
      } catch (err) {
        alert(err);
      }
    },
    async deleteInterval(id) {
      try {
        await this.$store.dispatch('deleteFeedIntervals', {
          id: id,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Intervalo apagado com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        await this.getInterval();
      } catch (err) {
        alert(err);
      }
    },
    async deleteShipping(id) {
      try {
        await this.$store.dispatch('deleteFeedShipping', {
          id: id,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Shipping apagado com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        await this.shippingFeed();
      } catch (err) {
        alert(err);
      }
    },
    async addMargem() {
      try {
        await this.$store.dispatch('addMargemFeed', {
          feed: this.feed,
          margem: this.margem,
          maximo: this.maximo,
          minimo: this.minimo,
          country: this.country,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Margem adicionada com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.$refs['modal_margem'].hide();
        this.country = 'all';
        this.feed = '';
        this.shipping = '';
        this.maximo = '';
        this.minimo = '';
        await this.getMargem();
      } catch (err) {
        alert(err);
      }
    },
    async openModal(modal) {
      this.$refs[modal].show();
    },
    async addNoProduct() {
      console.log(this.product);
      try {
        await this.$store.dispatch('addNoProductFeed', {
          sku: this.product[0].sku[0],
          feed: this.feed,
          nameProduct: this.product[0].name,
          id_product: this.product[0].id_backoffice,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Produto adicionado com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.$refs['modal_product'].hide();
        this.product = [];
        this.feed = '';
        this.skuSearch = '';
        await this.noProductFeed();
      } catch (err) {
        alert(err);
      }
    },
    async noProductFeed() {
      try {
        await this.$store.dispatch('noProductFeed');
        this.noProducts = this.getNoProductsFeed;
      } catch (err) {
        alert(err);
      }
    },
    routeToProduct(productId) {
      window.open(`https://${window.location.host}/product/${productId}`, '_blank');
    },
    deleteProduct(id) {
      try {
        this.$store.dispatch('deleteNoProductFeed', {
          id: id,
        });
        this.$swal({
          toast: true,
          icon: 'success',
          title: 'Produto apagado com sucesso',
          showConfirmButton: false,
          timer: 3000,
          position: 'top-end',
        });
        this.noProductFeed();
      } catch (err) {
        alert(err);
      }
    },
    async filterNoProducts() {
      try {
        await this.$store.dispatch('filterNoProductsFeed', {
          sku: this.skuProduct,
          feed: this.feedSearch,
        });
        this.noProducts = this.getNoProductsFeed;
      } catch (err) {
        alert(err);
      }
    },
  },
  computed: {
    ...mapGetters(['getAllFeedSettings']),
    ...mapGetters(['getAllFeedValues']),
    ...mapGetters(['getAllFeedInterval']),
    ...mapGetters(['getAllFeedMargem']),
    ...mapGetters(['getAllFeedMargemByFeed']),
    ...mapGetters(['getAllFeedShipping']),
    ...mapGetters(['getProduct']),
    ...mapGetters(['getNoProductsFeed']),
  },
  async created() {
    await this.getFeedSettings();
    await this.getFeedValues();
    await this.getInterval();
    await this.shippingFeed();
    await this.getMargem();
    await this.noProductFeed();
  },
};
</script>
<style scoped>
table {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.table {
  align-items: center;
}
a {
  cursor: pointer;
  /* color: rgb(16, 74, 235); */
  text-decoration: solid;
}
</style>
